<template>
    <!--    <v-card class="py-1" v-if="lessons.length <= 0">-->
    <!--        현재 매칭이 진행중입니다.-->
    <!--    </v-card>-->
    <div>
        <div class="py-1 all-lessons-background rounded-xl" v-if="this.allLessons.length > 0">
            <!--{{lessons}}-->
            <v-card elevation="5"
                    class="ma-4 pb-3 rounded-xl" v-for="item in allLessons"
                    max-width="800"
                    :key="item.id">
                <!--            {{item.updated_at}}-->
                <!--            v-if="item.lesson_status == 0"-->
                <div>
                    <!--                NOT_STARTED-->
                    <!--                {{typeof item.detailed_lesson_times}}-->
                    <v-chip v-if="item.lesson_status == 0" class="ma-3 accent--text" color="#A20916"> 요청</v-chip>
                    <v-chip v-if="item.lesson_status == 1 || item.lesson_status == 5" class="ma-3 accent--text"
                            color="#579004"> 준비 중
                    </v-chip>
                    <p class="primary--text ma-5" v-if="item.lesson_status == 1 || item.lesson_status == 5">
                        수업 진행하실 과외방 생성 후 연락드리겠습니다. 🙂
                    </p>
                    <v-chip v-if="item.lesson_status == 6" class="ma-3 accent--text" color="#0058DC"> 매칭 완료</v-chip>
                    <!--                <div class="text-subtitle-2 ma-2"> 요청 시간: {{new Date(item.updated_at)}}</div>-->
                    <!-- show only if lesson status == NOT_STARTED -->
                    <!--                                    {{item.lesson_status}}-->
                    <!--                                    {{item.student_id}}-->
                    <!--                                    {{item.lesson_id}}-->
                    <!--                                    {{item}}-->

                    <!--                                    <h3>{{item.lesson_status}}</h3>-->
                    <v-card-title class="font-weight-bold text-decoration-underline">
                        학생 정보
                    </v-card-title>
                    <div class="mx-5">
                        <!--                    <span class="font-weight-bold">{{item.school}} ({{item.school_type}}), {{item.current_location}}</span>-->
                        <strong>{{item.current_location}}</strong> 거주 중이며 <strong>{{item.school}}</strong> (<strong>{{item.school_type}}</strong>)
                        <strong>{{item.current_grade}}</strong>
                        학생입니다.
                        <br/>
                        진학 희망 전공 계열은 <strong>{{item.desired_major}}</strong> 입니다. 📚
                        <br/>
                        <!--                    재학중인-->
                        <!--                    <span class="font-weight-bold"> {{item.desired_major}}</span> 계열 전공 진학을-->
                        <!--                    희망하는-->
                        <!--                    <span class="font-weight-bold">{{item.current_grade}}</span> 학생이에요.-->
                        <br/>

                    </div>
                    <div class="mx-5" v-if="item.lesson_status == 6">

                    <span class="font-weight-bold ">
                         학생명:
                        </span> {{item.student_last_name}} {{item.student_first_name}}
                        <br/>

                        <span class="font-weight-bold">
                        카톡 ID:
                        </span> {{item.kakao_id}}
                        <br/>
                        <span class="font-weight-bold">
                        이메일:
                        </span> {{item.student_email}}
                        <br/>
                        <span class="font-weight-bold">
                        전화번호:
                    </span>
                        <span v-if="item.country_code == '직접 입력'">{{item.phone_number}}</span>
                        <span v-if="item.country_code != '직접 입력' && item.country_code != null"> (+{{item.country_code.replace(/\D/g,'')}}) {{item.phone_number}}</span>
                        <br/>

                    </div>

                    <v-card-title class="font-weight-bold text-decoration-underline">
                        <span v-if="item.lesson_status != 6"> 희망</span> 수업 정보

                    </v-card-title>
                    <div class="mx-5">
                        <!--                                        <span v-for="value in item.preferred_lesson_style" :key="value">-->
                        <!--                                        {{value}}-->
                        <!--                                        </span>-->
                        <span>

                        <span class="" v-if="item.lesson_status != 6">도움이 필요한 과목은
                            <span class="font-weight-bold text-decoration-underline">
                                {{item.subjects_of_interest}} - {{item.sub_subjects}}
                            </span>
                            입니다.
                        </span>
                        <div v-if="item.lesson_status == 6">
                        <span class="font-weight-bold">
                        과목:
                        </span> {{item.subjects_of_interest}} - {{item.sub_subjects}}
                        <br/>
                        <span class="font-weight-bold">
                        수업 횟수:
                        </span> 주 {{(item.expected_number_of_lessons_per_week+"").charAt(0)}}회 {{(item.expected_number_of_lessons_per_week+"").slice(1)}}분 수업
                        <br/>
                            </div>
                        <div v-if="item.lesson_status != 6">
                        <br/>
                        </div>

                                                            <span class="font-weight-bold">
                                                                선호 수업 방식:
                                                            </span> {{item.preferred_lesson_style}}
                                                            <br/>
                                                            </span>
                        <span>
                                                            <span class="font-weight-bold">
                                                                선호 교재:
                                                            </span> {{item.preferred_lesson_material}}
                                                                <br/>
                                                                </span>
                        <span>
                                                            <span class="font-weight-bold">
                                                                수업 목적:
                                                            </span> {{item.goal_of_lesson}}
                                                                <br/>
                                                                </span>
                        <span v-if="item.lesson_remarks != null">
                        <span class="font-weight-bold">
                            기타 요청 사항:
                        </span> {{item.lesson_remarks}}
                        <br/>
                    </span>
                    </div>
                    <div v-if="item.lesson_status != 6">
                        <v-card-title class="font-weight-bold text-decoration-underline">
                            선호 일정
                        </v-card-title>
                        <div class="mx-5">
                    <span>
                        일주일에
                        <span class="font-weight-bold">  {{item.expected_number_of_lessons_per_week}} 번씩 </span> 진행을 원해요!
                    </span>
                            <br/>
                            <br/>
                            <div class="">
                                <div v-show="item.detailed_lesson_times.monday.length > 0">
                                    <!--                                            <span class="font-weight-bold"> 월요일 </span>: {{item.detailed_lesson_times.monday}}-->
                                    <span class="font-weight-bold"> 월요일 </span>:
                                    <li class="ml-5" v-for="value in item.detailed_lesson_times.monday" :key="value">
                                        {{value}}
                                    </li>
                                    <v-divider class="ma-2"></v-divider>

                                </div>
                                <div v-show="item.detailed_lesson_times.tuesday.length > 0">
                                    <span class="font-weight-bold"> 화요일 </span>:
                                    <li class="ml-4" v-for="value in item.detailed_lesson_times.tuesday"
                                        :key="value">
                                        {{value}}
                                    </li>
                                    <v-divider class="ma-2"></v-divider>

                                </div>
                                <div v-show="item.detailed_lesson_times.wednesday.length > 0">
                                    <span class="font-weight-bold"> 수요일 </span>:
                                    <li class="ml-4" v-for="value in item.detailed_lesson_times.wednesday"
                                        :key="value">
                                        {{value}}
                                    </li>
                                    <v-divider class="ma-2"></v-divider>

                                </div>
                                <div v-show="item.detailed_lesson_times.thursday.length > 0">
                                    <span class="font-weight-bold"> 목요일 </span>:
                                    <li class="ml-4" v-for="value in item.detailed_lesson_times.thursday"
                                        :key="value">
                                        {{value}}
                                    </li>
                                    <v-divider class="ma-2"></v-divider>

                                </div>
                                <div v-show="item.detailed_lesson_times.friday.length > 0">
                                    <span class="font-weight-bold"> 금요일 </span>:
                                    <li class="ml-4" v-for="value in item.detailed_lesson_times.friday" :key="value">
                                        {{value}}
                                    </li>
                                    <v-divider class="ma-2"></v-divider>

                                </div>
                                <div v-show="item.detailed_lesson_times.saturday.length > 0">
                                    <span class="font-weight-bold"> 토요일 </span>:
                                    <li class="ml-4" v-for="value in item.detailed_lesson_times.saturday"
                                        :key="value">
                                        {{value}}
                                    </li>
                                    <v-divider class="ma-2"></v-divider>

                                </div>
                                <div v-show="item.detailed_lesson_times.sunday.length > 0">
                                    <span class="font-weight-bold"> 일요일 </span>:
                                    <li class="ml-4" v-for="value in item.detailed_lesson_times.sunday" :key="value">
                                        {{value}}
                                    </li>
                                    <v-divider class="ma-2"></v-divider>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="item.lesson_status == 0" class="text-center">
                        <v-btn color="secondary" class="mx-3 mt-7 mb-5"
                               @click.native="proceedLesson(item.lesson_id)">
                            요청 수락
                        </v-btn>
                    </div>
                    <v-divider v-if="item.lesson_status == 6" class="ma-3"></v-divider>

                    <div v-if="item.lesson_status == 6" class=" text-center mb-4 mt-2">
                        <row>
                            <v-btn class="my-3 mr-7" elevation="1" :href="item.page_call_link"
                                   target="_blank" color="secondary">과외방 입장
                            </v-btn>
                            <!--                        <v-btn class="my-3" elevation="1" outlined :href="item.report_link"-->
                            <!--                               target="_blank" color="secondary">수업일지-->
                            <!--                        </v-btn>-->
                            <v-btn class="my-3 mr-7" elevation="1" outlined to="/teacher/classreports"
                                    color="secondary">수업일지
                            </v-btn>

                                        <v-btn
                                                class="my-3"
                                                color="primary"
                                                dark
                                                @click="moveToCreateReportPage"
                                                v-show="!(item.sum_of_remaining_classes <= 0)"
                                        >
                                            수업일지 작성
                                        </v-btn>

                                        <div v-show="(item.sum_of_remaining_classes <= 0)">
                                            <v-btn  disabled>
                                                수업일지 작성
                                            </v-btn>
                                            <div style="font-size: 14px; color: darkred" class="pa-3 py-8 font-italic">
                                            학생의 잔여 수강권이 존재하지 않아 일지를 작성할 수 없습니다. 운영팀에게 문의 부탁 드립니다.
                                            </div>
                                        </div>

                        </row>
                    </div>
                </div>
            </v-card>
        </div>


    </div>
</template>

<script>
    import TeacherService from "../../../services/teacher.service"
    import StudentService from "../../../services/student.service"
    import LessonService from "../../../services/lesson.service"
    // import AllLessons from "./Lessons/AllLessons";
    import EmailService from "../../../services/email.service"
    import ClassService from "../../../services/class.service"

    export default {
        name: "TeacherProfile",
        components: {},
        computed: {
            tempTime() {
                const inputDate = new Date("2023-03-17T18:00:00.000Z");
                // {{"2023-03-17T18:00:00.000Z".substring(0, 15)}}
// Define the options for formatting
                const options = {
                    timeZone: "Asia/Seoul",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    hour12: false,
                };

// Convert the date to the desired format
                return inputDate.toLocaleString("en-KR", options);

            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            allLessons: function () {
                return this.lessons.filter(function (lesson) {
                    return lesson.lesson_status == 0 || lesson.lesson_status == 1 || lesson.lesson_status == 5 || lesson.lesson_status == 6;
                })
            },
            timeRules() {
                return [
                    (value) => {
                        if (!value) {
                            return 'Please enter a time.';
                        }
                        if (value.length !== 2) {
                            return 'Time must be 2 characters long.';
                        }
                        if (!/^\d+$/.test(value)) {
                            return 'Time must contain only digits.';
                        }
                        const time = Number(value);
                        if (time < 0 || time > 23) {
                            return 'Time must be between 0 and 23.';
                        }
                        return true;
                    }
                ];
            },
            required: (value) => !!value || '필수 입력',
            isDisabled() {
                return !this.classReport || !this.homework || !this.lessonScore || !this.classCreateRequestBody.numberOfHours
                    || !this.timezone || !this.year || !this.month || !this.date
                    || !this.startHour || !this.startMinute || !this.endHour || !this.endMinute || this.disableButton;
                //    !this.numberOfHours || !this.month || !this.date || !this.year || !this.month || !this.date ||
            },
            classAt() {

                // if (this.classTimeAt.time.length > 0) {
                //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                //     this.classTimeAt.time = ;
                // }

                let tempTime = this.classTimeAt.date + " " + this.convertTo24Hour(this.classTimeAt.time.toLowerCase()) + ":00.000"
                return tempTime;
            },
            currentTime() {
                let currentDate = new Date(Date.now());
                const offset = currentDate.getTimezoneOffset()
                currentDate = new Date(currentDate.getTime() - (offset * 60 * 1000))
                let dateList = currentDate.toISOString().replace('Z', '').split('T');
                currentDate = dateList[0] + " " + dateList[1]
                return currentDate;
            },
        },
        mounted() {

            if (!this.currentUser) {
                this.$router.push('/login');
            }
            TeacherService.getStudentsWhoHaveLessonsWithTeacherId(this.currentUser.id).then(
                (response) => {

                    this.lessons = response.data;
                    // this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)

                    this.parseLessonTimes()
                    // for (let i = 0; i < this.lessons.length; i++) {
                    //
                    // }
                    // console.log("lessons:")
                    // console.log(this.lessons)
                    // console.log("lessons:")
                    // this.parseLessonTimeJson(this.lessons)


                },
                (error) => {
                    this.lessons =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    // console.log(this.lessons)
                }
            );

            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.setLessonStatuses(this.teacherContent)
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },

        data: () => ({
            loading: false,
            isFormValid: false,
            classCreateRequestBody: {
                lessonId: "",
                classTimeAt: "",
                verifiedAt: "",
                paidAt: "",
                status: "",
                pageCallLink: "",
                reportLink: "",
                teacherHourlyWage: "",
                numberOfHours: "",
                totalAmountToBePaid: "",
                adminRemarks: "",
                classReport: "",
            },
            verifiedAt: {
                date: "",
                time: ""
            },
            classTimeAt: {
                date: "",
                time: ""
            },
            classEndedAt: {
                date: "",
                time: ""
            },
            paidAt: {
                date: "",
                time: ""
            },
            homework: "",
            classReport: "",
            lessonScore: "",
            lessonScores: [1, 2, 3],
            classHours: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6],
            timezones: [
                "KST",
                "PST",
                "EST",
                "CET",
                "SGT",

            ],
            timezone: "",
            startHour: "",
            startMinute: "",
            endHour: "",
            endMinute: "",
            year: "",
            month: "",
            date: "",
            years: [
                "2023"
            ],
            months: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
            ],

            days: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            dialog: false,
            matchingOn: false,
            showLesson: false,
            lesson_asked: false,
            testValue: 1,
            lesson_message_sent_by_student: false,
            lesson_no_lessons: false,
            lesson_accepted: false,
            teacherLessons: {
                lesson_asked: [],
                lesson_message_sent_by_student: [],
                lesson_no_lessons: [],
                lesson_accepted: [],
            },
            lessonRequestBody: [],
            lessonResponseBody: [],
            lessonStudents: [],
            lessons: [],
            teacherContent: "",
            studentLessonContent: "",
            teacherLessonContent: "",
            studentContent: "",
            value: '',
            disableButton: false,

        }),
        methods: {

            moveToCreateReportPage(studentId) {
                this.$router.push({name: 'ClassReportForm', params: {studentId}});
            },

            timeToShowInReportEmail(dateString) {
                const inputDate = new Date(dateString);
                // {{"2023-03-17T18:00:00.000Z".substring(0, 15)}}
// Define the options for formatting
                const options = {
                    timeZone: "Asia/Seoul",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    hour12: false,
                };

// Convert the date to the desired format
                return "[KST] " + inputDate.toLocaleString("en-KR", options) + "시";
            },

            convertToKST(dateString, timeZone) {
                const date = new Date(dateString);
                const utcOffset = date.getTimezoneOffset() * 60 * 1000;
                const targetTimeZone = timeZone.toUpperCase();

                const timeZones = {
                    PST: 34,
                    EST: 31,
                    CET: 26,
                    SGT: 19
                    // Add more time zones and their respective offsets here
                };

                const kstOffset = 9;
                if (timeZone == "KST") {
                    return new Date(date.getTime())
                }

                else if (Object.prototype.hasOwnProperty.call(timeZones, targetTimeZone)) {
                    const targetOffset = timeZones[targetTimeZone];
                    const convertedTime = new Date(date.getTime() + (targetOffset - kstOffset) * 60 * 60 * 1000 + utcOffset);
                    // const options = {
                    //     year: "numeric",
                    //     month: "2-digit",
                    //     day: "2-digit",
                    //     hour12: false,
                    //     hour: "2-digit",
                    //     minute: "2-digit",
                    // };
                    return convertedTime;
                    // .toLocaleTimeString("en-US",options);
                } else {
                    throw new Error('Invalid time zone specified.');
                }
            },

            sendReportUpdateEmail(requestBody) {
                EmailService.sendReportUpdateEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                        // because we send email to teacher after student, refresh after sending email to Teacher
                        if (requestBody.teacherId) {
                            this.$router.go(0)
                        }
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            convertTo24Hour(time) {
                var hours = parseInt(time.substr(0, 2));
                if (time.indexOf('am') != -1 && hours == 12) {
                    time = time.replace('12', '0');
                }
                if (time.indexOf('pm') != -1 && hours < 12) {
                    time = time.replace(hours, (hours + 12));
                }
                return time.replace(/(am|pm)/, '');
            },

            createClass(requestBody, lessonId, hourlyWage, studentEmail, teacherId, reportLink, classReport, classEndedAt, homework, lessonScore, timezone) {
                // this.classCreateRequestBody.

                this.disableButton = true;

                requestBody.classTimeAt = this.year + "-" + this.month + "-" + this.date + " "
                    + this.startHour + ":" + this.startMinute + ":" + "00";
                requestBody.classEndedAt = this.year + "-" + this.month + "-" + this.date + " "
                    + this.endHour + ":" + this.endMinute + ":" + "00";


                requestBody.verifiedAt = this.currentTime;
                requestBody.lessonId = lessonId + "";


                requestBody.numberOfHours = requestBody.numberOfHours + "";
                // 선생에게 지급해야할 액수 저장.
                // requestBody.teacherHourlyWage;
                // 2023-05-14 12:00:00.000


                requestBody.totalAmountToBePaid = requestBody.numberOfHours * hourlyWage;
                requestBody.classReport = classReport;

                requestBody.homework = homework;
                requestBody.lessonScore = lessonScore + "";
                requestBody.timezone = timezone;
                requestBody.status = "FINISHED";
                // requestBody.numberOfHours = "2"; // temp
                this.loading = true;
                // this.dialog = false;

                ClassService.createClassForConfirmation(requestBody).then(
                    (response) => {
                        console.log(response.data)
                        // to student
                        this.sendReportUpdateEmail({
                            "studentEmail": studentEmail,
                            // "classTimeAt": requestBody.classTimeAt.substring(0, requestBody.classTimeAt.length - 7),
                            "classTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.classTimeAt, timezone)),
                            "reportLink": reportLink,
                            "classReport": requestBody.classReport,
                            "homework": requestBody.homework,
                            "emailBody": "STUDENT_REPORT_NOTIFICATION",

                        });
                        // to teacher
                        this.sendReportUpdateEmail({
                            "teacherId": teacherId + "",
                            // "classTimeAt": this.convertToKST(requestBody.classTimeAt, timezone),
                            "studentEmail": studentEmail,
                            "classTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.classTimeAt, timezone)),
                            "reportLink": reportLink,
                            "emailBody": "TEACHER_REPORT_NOTIFICATION"
                        });
                        this.sendNotificationEmail({
                            "email": "support@ivyed.co.kr",
                            "teacherId": teacherId + "",
                            "studentEmail": studentEmail,
                            "classTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.classTimeAt, timezone)),
                            "emailBody": "CLASS_CREATED_NOTIFICATION"

                        });
                        this.loading = false;
                        console.log("here")
                        console.log(response.data)
                        console.log("here")


                        // Code to be executed after the delay (3 seconds)
                        // this.$router.push("/teacher/classreports");

                        // This is where you can place the logic to hide the loading indicator or perform any other action


                        //
                        // // to teacher
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "TEACHER_REPORT_NOTIFICATION",
                        // }, teacherId);
                    },
                    (error) => {
                        this.classContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log("error below")
                        console.log(error.response)
                    },
                )
            },


            getStudentInfoFromLessons(studentId) {
                for (let i = 0; i < this.lessons.length; i++) {
                    // console.log("what")
                    // console.log(this.lessons[i])
                    // console.log("what")
                    if (this.lessons[i].student_id == studentId) {
                        return this.lessons[i];
                    }
                }
            },

            submitTeacherInfo(userId, requestBody) {


                requestBody.registerStatus = 'PENDING'
                TeacherService.updateTeacherAboutTeacher(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            parseLessonTimes() {
                for (let i = 0; i < this.allLessons.length; i++) {
                    this.allLessons[i].detailed_lesson_times = this.allLessons[i].detailed_lesson_times.replace(/\\/g, '');
                    let temp = this.allLessons[i].detailed_lesson_times
                    if (temp.charAt(0) == '"') {
                        temp = temp.substr(1);
                    }
                    if (temp.charAt(temp.length - 1) == '"') {
                        temp = temp.substr(0, temp.length - 1);
                    }
                    if (typeof temp == 'string' || temp instanceof String) {
                        temp = JSON.parse(temp);
                        this.allLessons[i].detailed_lesson_times = temp;
                    }
                }
            },

            parseLessonTimeJson(lessons) {

                for (let i = 0; i < lessons.length; i++) {
                    // console.log("here")
                    // console.log(lessons[i].detailed_lesson_times)
                    // console.log("here")

                }
            },

            setLessonStatuses(responseBody) {


                for (let i = 0; i < responseBody.lessons.length; i++) {
                    // console.log(responseBody.lessons[i].teacherMatchingStatus)
                    if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ASKED') {
                        this.teacherLessons.lesson_asked.push(responseBody.lessons[i])
                        this.lesson_asked = true
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'MESSAGE_SENT_BY_STUDENT') {
                        this.lesson_message_sent_by_student = true;
                        this.teacherLessons.lesson_message_sent_by_student.push(responseBody.lessons[i])
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'NO_LESSONS') {
                        this.teacherLessons.lesson_no_lessons.push(responseBody.lessons[i]);
                        this.lesson_no_lessons = true;
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ACCEPTED') {
                        this.teacherLessons.lesson_accepted.push(responseBody.lessons[i]);
                        this.lesson_accepted = true;
                    }

                }
                // console.log(this.lesson_message_sent_by_student)
            },

            getStudentInfo(studentId) {
                StudentService.getStudentInfo(studentId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            getStudentInfoByLessonId(lessonId) {
                this.showLesson = true
                StudentService.getUserInfoByLessonId(lessonId).then(
                    (response) => {
                        this.lessonStudents = response.data;
                        // return response;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            sendNotificationEmail(requestBody) {
                EmailService.sendNotificationToAdminClassCreated(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            proceedLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'TEACHER_ACCEPTED',
                    studentMatchingStatus: 'FOUND',
                    teacherMatchingStatus: 'TEACHER_PROCEEDING',
                },
                    // this.sendNotificationEmail({
                    //     'email': "to-student",
                    //     'lessonId': lessonId + "",
                    //     'emailBody': 'TEACHER_PROCEEDING'
                    // });
                    LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                        (response) => {
                            this.lessonResponseBody = response.data;
                            this.sendNotificationEmail({
                                'email': "to-student",
                                'lessonId': lessonId + "",
                                'emailBody': 'TEACHER_PROCEEDING'
                            });
                            window.location.reload();
                            return response.data;
                        },
                        (error) => {
                            this.lessonResponseBody =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
            },

            confirmLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'SCHEDULED',
                    studentMatchingStatus: 'MATCHED',
                    teacherMatchingStatus: 'LESSON_ACCEPTED',
                },

                    LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                        (response) => {
                            this.lessonResponseBody = response.data;
                            window.location.reload();

                            return response.data;
                        },
                        (error) => {
                            this.lessonResponseBody =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
            },


            rejectLesson(lessonId, requestBody) {
                LessonService.updateLesson(lessonId, requestBody).then(
                    (response) => {
                        this.lessonResponseBody = response.data;
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


            getLessonInfo(lessonId) {
                LessonService.getLessonInfo(lessonId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


        }
    }
</script>


<style scoped>

    .all-lessons-background {
        background-color: #F0F0F0;
        max-width: 800px;
        /*width: 100%;*/
    }

    /* CSS for Approach 1 or Approach 2 */

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 9999;
    }

    .container {
        position: relative;
    }
</style>
