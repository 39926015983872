<template>
    <div class="main-container ">
        <!-- 선생님으로 가입했지만 아직 우리에게 등록하지 않았을시   -->
        <div v-if="!currentUser.isRegistered">
            <v-card class="mx-auto mt-n12 mb-15 black--text"
                    max-width="800"
                    elevation="0"
            >

                <div class="pt-13" v-if="teacherContent.registerStatus == 'NOT_STARTED'">

                    <span class=" mt-15">IVYED 선생님으로 <strong>등록</strong>하시면 저희가 <strong>{{currentUser.firstName}}</strong>님에게 딱 맞는 학생분들을 찾아드립니다.</span>
                    <v-divider class="mb-3 mt-3"></v-divider>

                    <div class=" mb-15"
                         :class="{
                                        'text-subtitle-2': $vuetify.breakpoint.mdAndDown,
                                        '': $vuetify.breakpoint.lgAndUp}">
                        <v-icon class="ma-2" color="primary">mdi-alert-circle-outline</v-icon>
                        <span>아직 아이비에드의 선생님으로 등록되지 않았습니다!</span>
                        <br/>
                        <!--                        <span class="ml-11 hidden-sm-and-down"> 학생으로 등록 하시면 선생님 매칭이 진행됩니다!</span>-->
                        <span class="ml-11"> 온보딩이 끝나면 매칭이 시작 됩니다. </span>
                        <!--                            <span class="ml-15 hidden-md-and-up"> 신청서를 등록해주셔야 <br/></span>-->
                        <!--                            <span class="ml-15 hidden-md-and-up">선생님 매칭이 진행됩니다! </span>-->
                        <div class="justify-center py-15 ">
                            <ul class="progressbar">
                                <li class="active">선생님 등록</li>
                                <li>Coffee Chat</li>
                                <li>온보딩</li>
                                <li>매칭 시작</li>
                            </ul>
                        </div>


                    </div>


                    <div class="mt-7 text-center">
                        <v-btn
                                class="ma-3"
                                color="secondary"
                                :to="'/teacher/register/finalDegree'"
                        > 선생님 등록
                        </v-btn>
                    </div>
                </div>
            </v-card>
            <v-card class="mx-auto mt-n12 black--text"
                    max-width="800"
                    elevation="0"
            >


                <!-- FORM FILLED / SUBMITTED TEACHERS -->
                <div class="" v-if="teacherContent.registerStatus == 'REGISTER_INFO_FILLED'">
                            <span :class="{
            'text-h7': $vuetify.breakpoint.mdAndDown,
            'text-h7': $vuetify.breakpoint.lgAndUp
            }"><strong>아이비에드</strong>에 등록해주셔 감사드립니다!</span>
                    <v-divider class="mb-3 mt-3"></v-divider>
                    <span :class="{
            'text-h7': $vuetify.breakpoint.mdAndDown,
            'text-h7': $vuetify.breakpoint.lgAndUp
            }">
                        작성 완료하신 <strong>선생님 신청서</strong>가 존재합니다. 아직 제출 하지 않으신것 같네요!
                            </span>
                    <div class="container py-15">
                        <ul class="progressbar">
                            <li class="active">선생님 가입</li>
                            <li>Coffee Chat</li>
                            <li>선생님 등록</li>
                            <li>매칭 시작</li>
                        </ul>
                    </div>
                    <div class="my-15 text-center">
                        <v-btn
                                color="secondary"
                                class=" my-5 mr-5"
                                @click="submitTeacherInfo(currentUser.id, teacherContent)"
                        > 신청서 바로 제출
                        </v-btn>
                        <v-btn
                                color="secondary"
                                outlined
                                class=""
                                to="/teacher/register/teacher/summary"
                        >
                            <v-icon color="secondary">mdi-pencil-outline</v-icon>
                            정보 수정하기
                        </v-btn>
                    </div>
                </div>


                <!-- FORM FILLED / SUBMITTED TEACHERS - PENDING -->
                <div class="mb-10" v-if="teacherContent.registerStatus == 'PENDING'">

<!--                    <v-icon color="primary">mdi-check-outline</v-icon>-->
                    <strong>IVYED</strong> 선생님 등록 신청서 제출이 완료 되었습니다!
                    <v-divider class="mb-3 mt-3"></v-divider>

                    <p class="black--text">신청서 검토 후 3영업일 내로 연락 드리겠습니다. 😊</p>

                    <v-card class="pb-10" elevation="0">
                        <div class="container py-15 mb-15">
                            <ul class="progressbar">
                                <li>선생님 등록</li>
                                <li class="active">Coffee Chat</li>
                                <li>온보딩</li>
                                <li>매칭 시작</li>
                            </ul>
                        </div>
                    </v-card>


                    <v-card class="primary--text mt-15"
                            align="center"
                            outlined
                            elevation="0"
                    >

                        <a style="text-decoration: none !important;" target="_blank" href="https://pf.kakao.com/_NxlSSb/chat">                        <p class="ma-3 text-subtitle-2">
                            <v-icon size="20px">$vuetify.icons.kakao</v-icon>
                         &nbsp; <strong>IVYED 카카오 채널</strong>로<br/> 언제든지 추가 문의주세요.
                        </p></a>
<!--                        <p class="ma-3"><a target="_blank" href="">-->
<!--                            <v-icon size="40px">$vuetify.icons.notion</v-icon>-->
<!--                        </a> &nbsp; <strong>선생님 온보딩 절차</strong>를 알아보세요!-->
<!--                        </p>-->

                    </v-card>

                </div>


                <!-- COFFEE CHAT SCHEDULE -->
                <div class="mb-10" v-if="teacherContent.registerStatus == 'PENDING_COFFEE_CHAT'">

<!--                    <v-icon color="primary">mdi-check-outline</v-icon>-->
                    <strong>IVYED</strong> 선생님 등록 신청서 리뷰가 완료 되었습니다!
                    <v-divider class="mb-3 mt-3"></v-divider>

                    <p>선생님과 좀 더 알아보기 위해 15분 내외의 Coffee Chat 세션을 요청드립니다. <br/><br/> 아래 링크를 통해 편하신 시간으로 요청 부탁드립니다.</p>

                    <v-card class="pb-10" elevation="0">
                        <div class="container py-15 mb-15">
                            <ul class="progressbar">
                                <li>선생님 등록</li>
                                <li class="active">Coffee Chat</li>
                                <li>온보딩</li>
                                <li>매칭 시작</li>
                            </ul>
                        </div>
                    </v-card>
                    <v-card class="primary--text mt-15"
                            align="center"
                            elevation="0"
                    >
                        <v-btn
                                x-large
                                color="primary"
                                outlined
                                href="https://whattime.co.kr/ivyed/coffeechat"
                                target="_blank"
                        >

                            Coffee Chat 시간 선택
                        </v-btn>
                        <!--                        <WhatTime class="ignore-parent" ></WhatTime>-->

                    </v-card>
                    <v-card class="primary--text mt-15"
                            align="center"
                            outlined
                            elevation="0"
                    >
                        <a style="text-decoration: none !important;" target="_blank" href="http://pf.kakao.com/_NxlSSb/chat">
                        <p class="ma-3 text-subtitle-2">
                            <v-icon size="20px">$vuetify.icons.kakao</v-icon>
                         &nbsp; <strong>IVYED 카카오 채널</strong>로<br/> 언제든지 추가 문의주세요.
                        </p>
                        </a>
<!--                        <p class="ma-3"><a target="_blank" href="">-->
<!--                            <v-icon size="40px">$vuetify.icons.notion</v-icon>-->
<!--                        </a> &nbsp; <strong>선생님 온보딩 절차</strong>를 알아보세요!-->
<!--                        </p>-->

                    </v-card>

                </div>

                <!--BEFORE_CONTRACT_SIGNING-->
                <div class=" mb-10" v-if="teacherContent.registerStatus == 'BEFORE_CONTRACT_SIGNING'">

<!--                    <v-icon color="primary">mdi-check-outline</v-icon>-->
                    커피챗에 응해 주셔 감사합니다!
                    <v-divider class="mb-3 mt-3"></v-divider>

                    <p><strong>IVYED</strong> 선생님으로 정식 등록되기 위한 프리랜서 계약서가 발송되었습니다. <br/><br/>전자 서명 후 제출하셔서 선생님을 필요로 한 학생들을 만나보세요!</p>

                    <v-card class="pb-10" elevation="0">
                        <div class="container py-15 mb-15">
                            <ul class="progressbar">
                                <li>선생님 등록</li>
                                <li>Coffee Chat</li>
                                <li class="active">온보딩</li>
                                <li>매칭 시작</li>
                            </ul>
                        </div>
                    </v-card>
<!--                    <v-card class="primary&#45;&#45;text mt-15"-->
<!--                            align="center"-->
<!--                            elevation="0"-->
<!--                    >-->
<!--                        <v-btn-->
<!--                                x-large-->
<!--                                color="primary"-->
<!--                                outlined-->
<!--                                href=""-->
<!--                                target="_blank"-->
<!--                        >-->

<!--                            프리랜서 계약서 확인하기-->
<!--                        </v-btn>-->
<!--                        &lt;!&ndash;                        <WhatTime class="ignore-parent" ></WhatTime>&ndash;&gt;-->

<!--                    </v-card>-->
                    <v-card class="primary--text mt-15"
                            align="center"
                            outlined
                            elevation="0"
                    >
                        <a style="text-decoration: none !important;" target="_blank" href="http://pf.kakao.com/_NxlSSb/chat">
                        <p class="ma-3 text-subtitle-2">
                            <v-icon size="20px">$vuetify.icons.kakao</v-icon>
                         &nbsp; <strong>IVYED 카카오 채널</strong>로<br/> 언제든지 추가 문의주세요.
                        </p>
                        </a>
<!--                        <p class="ma-3"><a target="_blank" href="">-->
<!--                            <v-icon size="40px">$vuetify.icons.notion</v-icon>-->
<!--                        </a> &nbsp; <strong>선생님 온보딩 절차</strong>를 알아보세요!-->
<!--                        </p>-->

                    </v-card>

                </div>


                <!-- REGISTERED TEACHERS -->
                <v-container v-if="teacherContent.registerStatus == 'REGISTERED'">
<!--                    <div class=" mb-4" v-if="teacherContent.teacherMatchingStatus == 'NO_LESSONS'">-->
<!--                        &lt;!&ndash;                            <div class="text-overline mb-4" v-show="">&ndash;&gt;-->
<!--                        <span-->
<!--                                class="font-weight-bold text-h6"-->
<!--                        >IVYED 등록을 환영합니다! </span>-->
<!--                        <v-divider class="mb-3 mt-3"></v-divider>-->
<!--&lt;!&ndash;                        <v-card class="pb-10" elevation="0">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="container py-15 mb-15">&ndash;&gt;-->
<!--&lt;!&ndash;                                <ul class="progressbar">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <li>선생님 가입</li>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <li>Coffee Chat</li>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <li>선생님 등록</li>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <li class="active">매칭 시작</li>&ndash;&gt;-->
<!--&lt;!&ndash;                                </ul>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </v-card>&ndash;&gt;-->

<!--                        <v-card class="pa-4 black&#45;&#45;text" outlined>-->
<!--                            <div class="ml-4">-->
<!--                                <p class="">현재 <strong>매칭 진행중</strong>입니다.</p>-->
<!--                                <p class=""> 진행이 완료 되면 <strong>{{currentUser.firstName}} </strong>선생님께 바로 연락 드리도록-->
<!--                                    하겠습니다.</p>-->
<!--                                <br/>-->
<!--                                <v-divider class="mb-3"></v-divider>-->
<!--                                <p class="font-weight-bold">-->
<!--                                    <v-icon color="topbar">mdi-information-outline</v-icon>-->
<!--                                    알고 계신가요?-->
<!--                                </p>-->
<!--                                <p class="">-->
<!--                                    언제든지 매칭을 원치 않으시면 선생님의 상태를 변경 가능합니다! :)-->
<!--                                    <br/>-->
<!--                                    <v-btn class="text-subtitle-1 ma-5" to="/profile/edit/teacher" color="secondary"-->
<!--                                           outlined text>-->
<!--                                        <strong>바로가기</strong>-->
<!--                                    </v-btn>-->
<!--                                </p>-->
<!--                            </div>-->
<!--                            <div class="ml-4" v-if="!teacherContent.matchingActivated">-->
<!--                                <p>지금 선생님의 매칭 희망 여부는 - <span class="font-weight-bold">비희망 (비활성화) 상태입니다.</span></p>-->
<!--                            </div>-->
<!--                        </v-card>-->

<!--                    </div>-->

                    <!-- 여기서 부터 Lesson 별로 status check 해야됨. -->
                    <!--                            <div class="text-overline mb-4"-->
                    <!--                                 v-if="teacherContent.teacherMatchingStatus =='LESSON_ASKED'">-->
                </v-container>


                <!-- REGISTERED TEACHER WITH LESSONS-->
<!--                {{lessons}}-->
<!--                <div class="" v-if="teacherContent.registerStatus == 'REGISTERED'">-->
                <div class="registered-container" v-if="teacherContent.registerStatus == 'REGISTERED'">
<!--                    <p v-if="teacherContent.teacherMatchingStatus == 'NO_LESSONS'"-->
<!--                          class=" font-weight-bold text-h6 text-center "-->
<!--                    >IVYED 등록을 환영합니다! </p>-->
                    <v-chip-group
                            mandatory
                            active-class="" column>
                        <v-chip color=""  class="ma-2" @click="showAllLessons()">
                            전체 ({{lessons.length - teacherLessons.cancelled.length}})
                        </v-chip>
<!--                        <v-chip class="ma-2" color="primary" outlined @click="showLessonRequests()">-->
<!--                        <v-chip class="ma-2" color=""  @click="showLessonRequests()">-->
<!--                            요청 ({{teacherLessons.lesson_asked.length}})-->
<!--                        </v-chip>-->
<!--                        &lt;!&ndash;            <v-icon>mdi-menu-right</v-icon>&ndash;&gt;-->
<!--                        &lt;!&ndash;            {{lessons}}&ndash;&gt;-->
<!--&lt;!&ndash;                        <v-chip class="ma-2" color="#579004" outlined @click="showLessonsOnStandby()">&ndash;&gt;-->
<!--                        <v-chip class="ma-2" color=""  @click="showLessonsOnStandby()">-->
<!--                            준비 중 ({{teacherLessons.lessons_on_standby.length + teacherLessons.both_accepted.length}})-->
<!--                        </v-chip>-->
<!--&lt;!&ndash;                        <v-chip class="ma-2" color="#0058DC" outlined @click="showScheduledLessons()">&ndash;&gt;-->
<!--                        <v-chip class="ma-2" color=""  @click="showScheduledLessons()">-->
<!--                            매칭 완료 ({{teacherLessons.scheduled.length}})-->
<!--                        </v-chip>-->
                    </v-chip-group>
                    <!-- 2022-06-10 change logic so that below message shows when lessons.length == 0 -->
<!--                    {{teacherContent}}-->
                    <div class=" mb-4" v-if="teacherContent.lessons && teacherContent.lessons.length == 0">
                        <!--                            <div class="text-overline mb-4" v-show="">-->

                        <v-divider class="mb-3 mt-3"></v-divider>
                        <!--                        <v-card class="pb-10" elevation="0">-->
                        <!--                            <div class="container py-15 mb-15">-->
                        <!--                                <ul class="progressbar">-->
                        <!--                                    <li>선생님 가입</li>-->
                        <!--                                    <li>Coffee Chat</li>-->
                        <!--                                    <li>선생님 등록</li>-->
                        <!--                                    <li class="active">매칭 시작</li>-->
                        <!--                                </ul>-->
                        <!--                            </div>-->
                        <!--                        </v-card>-->

                        <v-card class="pa-4 black--text" flat>
                            <div class="">
                                <p class="">현재 <strong>매칭 진행중</strong>입니다.</p>
                                <p class=""><strong>{{currentUser.firstName}} </strong>선생님과 맞는 학생을 찾고 연락 드리겠습니다! 🙂</p>
                                <br/>
<!--                                <v-divider class="mb-3"></v-divider>-->
<!--                                <p class="font-weight-bold">-->
<!--                                    <v-icon color="topbar">mdi-information-outline</v-icon>-->
<!--                                    알고 계신가요?-->
<!--                                </p>-->
<!--                                <p class="">-->
<!--                                    언제든지 매칭을 원치 않으시면 선생님의 상태를 변경 가능합니다! :)-->
<!--                                    <br/>-->
<!--                                    <v-btn class="text-subtitle-1 ma-5" to="/profile/edit/teacher" color="secondary"-->
<!--                                           outlined text>-->
<!--                                        <strong>바로가기</strong>-->
<!--                                    </v-btn>-->
<!--                                </p>-->
                            </div>
<!--                            <div class="ml-4" v-if="!teacherContent.matchingActivated">-->
<!--                                <p>지금 선생님의 매칭 희망 여부는 - <span class="font-weight-bold">비희망 (비활성화) 상태입니다.</span></p>-->
<!--                            </div>-->
                        </v-card>

                    </div>
                    <div>
                        <v-card-subtitle class="">
                            <div>
                                <v-icon class="pb-1" color="primary">mdi-information-outline</v-icon>
                                <span style=""> Friendly Reminders for all teachers,</span>
                            </div>
                            <div class="ml-10">
                                · please make sure to assign HW assignment(s) to your students! <br/>
                                · please update feedback report (수업일지)
                            </div>
                        </v-card-subtitle>
                    </div>

                </div>

                <!--        {{lessonFilters}}-->
                <AllLessons v-if="lessonFilters.showAll && teacherContent.teacherMatchingStatus != 'NO_LESSONS'"></AllLessons>
<!--                <v-card class="text-center ma-5" elevation="0" v-if="lessons.length == 0 && teacherContent.registerStatus == 'REGISTERED' && teacherContent.teacherMatchingStatus != 'NOT_STARTED'">-->
<!--                    현재 매칭 진행중입니다.-->
<!--                </v-card>-->

                <LessonRequests v-if="lessonFilters.showRequestsOnly"></LessonRequests>
                <LessonsOnStandby v-if="lessonFilters.showOnStandByOnly"></LessonsOnStandby>
                <TeacherScheduledLessons v-if="lessonFilters.showScheduled"></TeacherScheduledLessons>
<!--                <div v-if="teacherContent.registerStatus == 'REGISTERED' && teacherContent.teacherMatchingStatus != 'NO_LESSONS'">-->


<!--                </div>-->


            </v-card>

        </div>
<!--        <v-divider></v-divider>-->
        <!--    IF TEACHER & REGISTERED   -->
    </div>
</template>

<script>
    import TeacherService from "../../services/teacher.service"
    import StudentService from "../../services/student.service"
    import LessonService from "../../services/lesson.service"
    import AllLessons from "./Lessons/AllLessons";
    import LessonRequests from "./Lessons/LessonRequests";
    import LessonsOnStandby from "./Lessons/LessonsOnStandby";
    import TeacherScheduledLessons from "./Lessons/TeacherScheduledLessons";

    export default {
        name: "TeacherProfile",
        components: {TeacherScheduledLessons, LessonsOnStandby, AllLessons, LessonRequests},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            },
        },
        mounted() {

            if (!this.currentUser) {
                this.$router.push('/login');
            }
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    // console.log("===============")
                    // console.log(this.teacherContent)
                    // console.log("===============")
                    this.setLessonStatuses(this.teacherContent)
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (this.teacherContent == 'Access is denied' || this.teacherContent.includes('denied') || this.teacherContent.includes('401')) {
                        this.logOut();
                    }
                }
            );
            TeacherService.getStudentsWhoHaveLessonsWithTeacherId(this.currentUser.id).then(
                (response) => {

                    this.lessons = response.data;
                    // this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)

                    this.lessons = this.parseLessonTimes(this.lessons)
                    // console.log("lessons:")
                    // console.log(this.lessons)
                    // console.log("lessons:")
                    // this.parseLessonTimeJson(this.lessons)


                },
                (error) => {
                    this.lessons =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );


            // TeacherService.getTeacherLessonInfo(this.currentUser.id).then(
            //     (response) => {
            //         this.teacherLessonContent = response.data;
            //     },
            //     (error) => {
            //         this.teacherLessonContent =
            //             (error.response &&
            //                 error.response.data &&
            //                 error.response.data.message) ||
            //             error.message ||
            //             error.toString();
            //     }
            // );

        },
        data: () => ({
            lessonFilters: {
                showAll: true,
                showRequestsOnly: false,
                showOnStandByOnly: false,
                showScheduled: false,
            },
            matchingOn: false,
            showLesson: false,
            lesson_asked: false,
            testValue: 1,
            lesson_message_sent_by_student: false,
            lesson_no_lessons: false,
            lesson_accepted: false,
            teacherLessons: {
                lesson_asked: [],
                lesson_message_sent_by_student: [],
                lesson_no_lessons: [],
                lesson_accepted: [],
                lesson_ready: [],
                lessons_on_standby: [],
                both_accepted: [],
                scheduled: [],
                cancelled: [],
            },
            lessonRequestBody: [],
            lessonResponseBody: [],
            lessonStudents: [],
            lessons: [],
            teacherContent: "",
            studentLessonContent: "",
            teacherLessonContent: "",
            studentContent: "",
            type: 'month',
            types: ['month', 'week', 'day', '4day'],
            mode: 'stack',
            modes: ['stack', 'column'],
            weekday: [0, 1, 2, 3, 4, 5, 6],
            weekdays: [
                {text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6]},
                {text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0]},
                {text: 'Mon - Fri', value: [1, 2, 3, 4, 5]},
                {text: 'Mon, Wed, Fri', value: [1, 3, 5]},
            ],
            value: '',
            events: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            tab: null,
            bannerItems: [
                {
                    color: '#1F7087',
                    // src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
                    title: '비대면 수업은 어떻게 진행 되는지 알아보세요.',
                    artist: 'IVYeD 의 비대면 시스템을 알아보세요!',
                },
                {
                    color: '#952175',
                    // src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
                    title: '왜 IVYeD여야 하나요?',
                    artist: '동안..',
                },
            ],

        }),
        methods: {
            logOut() {
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            },
            getStudentInfoFromLessons(studentId) {
                for (let i = 0; i < this.lessons.length; i++) {
                    // console.log("what")
                    // console.log(this.lessons[i])
                    // console.log("what")
                    if (this.lessons[i].student_id == studentId) {
                        return this.lessons[i];
                    }
                }
            },

            submitTeacherInfo(userId, requestBody) {


                requestBody.registerStatus = 'PENDING'
                TeacherService.updateTeacherAboutTeacher(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            parseLessonTimes(lessons) {
                for (let i = 0; i < lessons.length; i++) {
                    lessons[i].detailed_lesson_times = JSON.parse(lessons[i].detailed_lesson_times)
                }
                return lessons;
            },

            parseLessonTimeJson(lessons) {

                for (let i = 0; i < lessons.length; i++) {
                    // console.log("here")
                    // console.log(lessons[i].detailed_lesson_times)
                    // console.log("here")

                }
            },

            setLessonStatuses(responseBody) {

                // console.log("here you go")
                // console.log(responseBody)
                // console.log("here you go")
                for (let i = 0; i < responseBody.lessons.length; i++) {
                    // console.log("??")
                    // console.log(responseBody.lessons[i].status)
                    // console.log("eeeee")
                    if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ASKED') {

                        this.teacherLessons.lesson_asked.push(responseBody.lessons[i])
                        this.lesson_asked = true
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'MESSAGE_SENT_BY_STUDENT') {
                        this.lesson_message_sent_by_student = true;
                        this.teacherLessons.lesson_message_sent_by_student.push(responseBody.lessons[i])
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'NO_LESSONS') {
                        this.teacherLessons.lesson_no_lessons.push(responseBody.lessons[i]);
                        this.lesson_no_lessons = true;
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ACCEPTED') {
                        this.teacherLessons.lesson_accepted.push(responseBody.lessons[i]);
                        this.lesson_accepted = true;
                    }
                    if (responseBody.lessons[i].status == 'BOTH_ACCEPTED') {
                        this.teacherLessons.both_accepted.push(responseBody.lessons[i]);
                        // this.lesson_accepted = true;
                    }
                    if (responseBody.lessons[i].status == 'TEACHER_ACCEPTED') {
                        this.teacherLessons.lessons_on_standby.push(responseBody.lessons[i])
                    }
                    if (responseBody.lessons[i].status == 'SCHEDULED') {
                        this.teacherLessons.scheduled.push(responseBody.lessons[i])
                    }
                    if (responseBody.lessons[i].status == 'CANCELLED') {
                        this.teacherLessons.cancelled.push(responseBody.lessons[i])
                    }

                }
            },

            getEvents({start, end}) {
                const events = []

                const min = new Date(`${start.date}T00:00:00`)
                const max = new Date(`${end.date}T23:59:59`)
                const days = (max.getTime() - min.getTime()) / 86400000
                const eventCount = this.rnd(days, days + 20)

                for (let i = 0; i < eventCount; i++) {
                    const allDay = this.rnd(0, 3) === 0
                    const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                    const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                    const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                    const second = new Date(first.getTime() + secondTimestamp)

                    events.push({
                        name: this.names[this.rnd(0, this.names.length - 1)],
                        start: first,
                        end: second,
                        color: this.colors[this.rnd(0, this.colors.length - 1)],
                        timed: !allDay,
                    })
                }

                this.events = events
            },
            getEventColor(event) {
                return event.color
            },
            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            getStudentInfo(studentId) {
                StudentService.getStudentInfo(studentId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            getStudentInfoByLessonId(lessonId) {
                this.showLesson = true
                StudentService.getUserInfoByLessonId(lessonId).then(
                    (response) => {
                        this.lessonStudents = response.data;
                        // console.log(this.lessonStudents)
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            proceedLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'TEACHER_ACCEPTED',
                    studentMatchingStatus: 'FOUND',
                    teacherMatchingStatus: 'TEACHER_PROCEEDING',
                }
                LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                    (response) => {

                        this.lessonResponseBody = response.data;
                        this.$router.go(0)
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
                // this.$router.push('/profile')
                // this.$router.push({
                //     path: "/profile",
                //     query: {
                //         q: this.searchQuery,
                //         t: new Date().getTime(),
                //     }
                // });
                // window.location.reload();

                // location.reload();
            },

            confirmLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'SCHEDULED',
                    studentMatchingStatus: 'MATCHED',
                    teacherMatchingStatus: 'LESSON_ACCEPTED',
                },

                    LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                        (response) => {
                            this.lessonResponseBody = response.data;
                            window.location.reload();

                            return response.data;
                        },
                        (error) => {
                            this.lessonResponseBody =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
            },

            rejectLesson(lessonId, requestBody) {
                LessonService.updateLesson(lessonId, requestBody).then(
                    (response) => {
                        this.lessonResponseBody = response.data;
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            getLessonInfo(lessonId) {
                LessonService.getLessonInfo(lessonId).then(
                    (response) => {
                        // console.log("get lesson info")
                        // console.log(response)
                        // console.log("get lesson info")
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            showAllLessons() {
                this.lessonFilters.showAll = true;
                this.lessonFilters.showRequestsOnly= false;
                this.lessonFilters.showOnStandByOnly=false;
                this.lessonFilters.showScheduled=false;
                // this.$router.go(0);
                //TODO
                // make every other option false
            },
            showLessonRequests() {
                this.lessonFilters.showRequestsOnly=true;
                this.lessonFilters.showAll = false;
                this.lessonFilters.showOnStandByOnly=false;
                this.lessonFilters.showScheduled=false;
                // this.$router.go(0);
            },
            showLessonsOnStandby() {
                this.lessonFilters.showRequestsOnly=false;
                this.lessonFilters.showAll = false;
                this.lessonFilters.showOnStandByOnly=true;
                this.lessonFilters.showScheduled=false;
                // this.$router.go(0);
            },

            showScheduledLessons() {
                this.lessonFilters.showRequestsOnly=false;
                this.lessonFilters.showAll = false;
                this.lessonFilters.showOnStandByOnly=false;
                this.lessonFilters.showScheduled=true;
                // this.$router.go(0);
            }
        }
    }
</script>

<style scoped>
    /* Helper classes */
    .button-82-pushable {
        position: relative;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
        outline-offset: 4px;
        transition: filter 250ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-82-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: hsl(0deg 0% 0% / 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    .button-82-edge {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: linear-gradient(
                to left,
                hsl(340deg 100% 16%) 0%,
                hsl(340deg 100% 32%) 8%,
                hsl(340deg 100% 32%) 92%,
                hsl(340deg 100% 16%) 100%
        );
    }

    .button-82-front {
        display: block;
        position: relative;
        padding: 12px 27px;
        border-radius: 12px;
        font-size: 1.1rem;
        color: white;
        background: hsl(345deg 100% 47%);
        will-change: transform;
        transform: translateY(-4px);
        transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    @media (min-width: 768px) {
        .button-82-front {
            font-size: 1.25rem;
            padding: 12px 42px;
        }
    }

    .button-82-pushable:hover {
        filter: brightness(110%);
    }

    .button-82-pushable:hover .button-82-front {
        transform: translateY(-6px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .button-82-pushable:active .button-82-front {
        transform: translateY(-2px);
        transition: transform 34ms;
    }

    .button-82-pushable:hover .button-82-shadow {
        transform: translateY(4px);
        transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .button-82-pushable:active .button-82-shadow {
        transform: translateY(1px);
        transition: transform 34ms;
    }

    .button-82-pushable:focus:not(:focus-visible) {
        outline: none;
    }

    .basil {
        background-color: #FFFBE6 !important;
    }

    .basil--text {
        color: #356859 !important;
    }

    .main-container {
        /*padding-top: 8%;*/
        /*padding-top: 50px;*/
        /*height: 800px;*/
        height: 100%;
        /*background-color: #7a00ff;*/
        /*width: 100%;*/
        /*justify-content: center;*/
        /*align-content: center;*/
        /*background: linear-gradient(30deg, #f43e4e 60%, #f65e6b 60%);;*/

    }

    .container {
        width: 100%;
        /*margin: 100px auto;*/
        justify-content: center;
    }

    .progressbar {
        counter-reset: step;
        padding-left: 0 !important;
    }

    .progressbar li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: #7d7d7d;
    }

    .progressbar li:before {
        width: 30px;
        height: 30px;
        content: counter(step);
        counter-increment: step;
        line-height: 30px;
        border: 2px solid #7d7d7d;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: white;
    }

    .progressbar li:after {
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #7d7d7d;
        top: 15px;
        left: -50%;
        z-index: -1;
    }

    .progressbar li:first-child:after {
        content: none;
    }

    .progressbar li.active {
        color: green;
    }

    .progressbar li.active:before {
        border-color: #55b776;
    }

    .progressbar li.active + li:after {
        background-color: #55b776;
    }

    .ignore-parent {
        height: auto !important;
    }

    .wrapper {
        max-width: 50rem;
        width: 100%;
        margin: 0 auto;
    }

    .tabs {
        position: relative;
        margin: 3rem 0;
        background: #A20916;
        height: 14.75rem;
    }

    .tabs::before,
    .tabs::after {
        content: "";
        display: table;
    }

    .tabs::after {
        clear: both;
    }

    .tab {
        float: left;
    }

    .tab-switch {
        display: none;
    }

    .tab-label {
        position: relative;
        display: block;
        line-height: 2.75em;
        height: 3em;
        padding: 0 1.618em;
        background: #A20916;
        border-right: 0.125rem solid #16a085;
        color: #fff;
        cursor: pointer;
        top: 0;
        transition: all 0.25s;
    }

    .tab-label:hover {
        top: -0.25rem;
        transition: top 0.25s;
    }

    .tab-content {
        height: 12rem;
        position: absolute;
        z-index: 1;
        top: 2.75em;
        left: 0;
        padding: 1.618rem;
        background: #fff;
        color: #2c3e50;
        border-bottom: 0.25rem solid #bdc3c7;
        opacity: 0;
        transition: all 0.35s;
    }

    .tab-switch:checked + .tab-label {
        background: #fff;
        color: #2c3e50;
        border-bottom: 0;
        border-right: 0.125rem solid #fff;
        transition: all 0.35s;
        z-index: 1;
        top: -0.0625rem;
    }

    .tab-switch:checked + label + .tab-content {
        z-index: 2;
        opacity: 1;
        transition: all 0.35s;
    }

    .expansion-panel-style {
        background-color: #ffcccb !important;

    }

    .right {
        position: absolute;
        right: 0px;
        /*border: 3px solid #73AD21;*/
        padding: 10px;
    }

    .expansion-panel-color {
        color: #5C060D;
        background-color: #5C060D !important;
    }
    .gray-background {
        background-color: #F0F0F0;
        max-width: 800px;
    }
    .force-remove-padding {
        padding-left: 0 !important;
    }
    .teacher-container {
        padding-top: 0 !important;
    }
    .registered-container {
        padding-top: 0px !important;
        margin-top: -28px;
    }
</style>
